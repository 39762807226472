<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center border-bottom">
            <h4 class="mr-auto color-333">{{ $t('recharge.title') }}</h4>
            <el-avatar size="small" :src="coinLogo[$route.params.coin] || info.logo" />
            <label class="ml-5" v-text="$route.params.coin"></label>
        </div>
        <div v-if="info">
            <div class="input-form  mx-auto">
                <div class="d-flex-center pt-20">
                    <el-button class="color-333 font-bold">{{ $t('wallet.sb') }}</el-button>
                    <el-select v-model="ni" prefix-icon="el-icon-money" :placeholder="$t('withdraw.swa')" class="w-100">
                        <el-option v-for="(item, index) in info.networks" :key="index" :label="item.label" :value="index" />
                    </el-select>
                </div>
                <!-- <div class="d-flex-center pt-20">
                    <el-button v-for="(item, index) in info.networks" :key="index" type="primary" size='small' :plain="ni !== index" @click="ni = index">{{ item.label }}</el-button>
                </div> -->
                <div class="pb-20">
                    <div class="p-20 d-flex-center">
                        <el-image style="width: 200px; height: 200px;" :src="baseURL + 'qrcode/wallet/address/'+info.networks[ni].address" />
                    </div>
                    <div class="font-14 text-center mb-20">{{ info.networks[ni].address }}</div>
                    <div class="text-center">
                        <el-button type="success" size='mini' icon="el-icon-document-copy" plain @click="onCopyAddress()">{{ $t('recharge.cc') }}</el-button>
                    </div>
                </div>
            </div>
            <div class="font-12 color-gray px-20 text-center mx-20 border-top">
                <p v-for="(item, index) in info.remark" :key="index">{{ item }}</p>
            </div>
        </div>
        <div v-else-if="!loadingData">
            <h2 class="text-center color-666">{{ $route.params.coin }} Forbidden to move in</h2>
        </div>
        <div v-loading="loadingRecord" class="mt-30">
            <div class="d-flex-center border-bottom  border-top">
                <h4 class="mr-auto color-333">{{ $t('recharge.history.title') }}</h4>
                <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('refresh') }}</el-button>
            </div>
            <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray py-15 border-bottom font-12">
                <el-col :span='5'>{{ $t('amount') }}</el-col>
                <el-col :span='5'>{{ $t('wallet.blockchain') }}</el-col>
                <el-col :span='8'>{{ $t('wallet.tx_hash') }}</el-col>
                <el-col :span='6' class="text-right">{{ $t('date_time') }}</el-col>
            </el-row>
            <div v-if="list.data.length">
                <el-row v-for="(item, index) in list.data" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-bottom font-14">
                    <el-col :span='5'>{{ item.amount }} {{ item.coin }}</el-col>
                    <el-col :span='5'>{{ networks[item.blockchain] }}</el-col>
                    <el-col :span='8' style="word-break: break-all">{{ item.hash }}</el-col>
                    <el-col :span='6' class="text-right">{{ item.create_time }}</el-col>
                </el-row>
                <div class="d-flex-center pt-20">
                    <el-pagination
                        :hide-on-single-page="true"
                        background
                        layout="prev, pager, next"
                        :page-size="list.per_page"
                        :current-page="list.current_page"
                        :total="list.total"
                        @current-change="onPage"
                    />
                </div>
            </div>
            <el-empty v-else :image-size="100" description="No Data"></el-empty>
        </div>
    </div>
</template>

<script>
import coinLogo from '@/assets/coin/logo'
import XEClipboard from 'xe-clipboard'
export default {
    data() {
        return {
            coinLogo,
            baseURL: process.env.VUE_APP_BASE_API_URL,
            info: null,
            ni: 0,
            loadingData: true,
            loadingRecord: false,
            networks: {
                tron: 'TRON / TRC20',
                bitcoin: 'Bitcoin',
                ethereum: 'Ethereum / ERC20'
            },
            query: { ...this.$route.query },
            list: {
                per_page: 1,
                current_page: 1,
                total: 0,
                data: [
                    // {
                    //     id: 1,
                    //     blockchain: 'tron',
                    //     amount: '100.000000',
                    //     coin: 'USDT',
                    //     hash: '0x9bc6cb7805e5724e1dd700128165cdb5951a39a666b35e92f4c792d29e98e0b9',
                    //     create_time: '2024-06-25 12:32:45'
                    // }
                ]
            },
            timer: null
        }
    },
    watch: {
        $route: function (to, from){
            if(to.params.coin === from.params.coin) return
            this.fatchData()
            this.fatchRecord()
        }
    },
    created: function() {
        this.fatchData()
        this.fatchRecord()
    },
    methods: {
        fatchData: async function () {
            this.loadingData = true
            try {
                const { data } = await this.$request.get('recharge/info_pc/coin/'+this.$route.params.coin)
                this.info = data
            } catch (error) {
                //
            }
            this.loadingData = false
        },
        onCopyAddress: function(){
            if(XEClipboard.copy(this.info.networks[this.ni].address)){
                this.$message({
                    message: this.$t('copied'),
                    type: 'success'
                })
            }
        },
        fatchRecord: async function () {
            this.loadingRecord = true
            try {
                const { data } = await this.$request.post('recharge/log_pc', this.query)
                this.list = data
            } catch (error) {
                //
            }
            this.loadingRecord = false
        },
        onSearch() {
            this.fatchRecord()
            this.$router.replace({
                query: this.query
            })
        },
        onPage(page) {
            this.query.page = page || 1
            this.onSearch()
        }
    }
}
</script>
<style lang="scss" scoped>
.input-form{
    width: 480px;
}
.voucher-img{
    width: 360px;
    height: 270px;
}
</style>